<template>
  <div class="service-network-page">
    <div class="service-net">
      <div class="net-img">
        <img src="imgs/chinaMap.jpg" alt="" />
        <p></p>
      </div>
      <div class="net-list">
        <div class="nlist">
          <span
            :class="[
              'nlist-item',
              contentIndex === index ? 'nlist-item-active' : '',
            ]"
            v-for="(item, index) in contentData"
            :key="index"
            @click="() => cut(index)"
            >{{ item.name }}</span
          >
        </div>
        <div class="new-info" v-if="contentData && contentData.length > 0">
          <h6 class="net-title">
            {{ contentData[contentIndex].news[0].brief }}
          </h6>
          <div v-html="contentData[contentIndex].news[0].content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      contentData: [],
      contentIndex: 0,
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      let menu = this.getSubMenu.childList.filter(
        (item) => item.router === this.getCurrentRoute
      )[0];
      if (menu.Interface) {
        this.$post(menu.Interface).then((res) => {
          this.contentData = res;
        });
      }
    },
    cut(index) {
      if (this.contentIndex === index) return
      this.contentIndex = index
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.service-network-page {
  width: 1200px;
  margin: 0 auto;
  .service-net {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .net-img {
      width: 675px;
      img {
        max-width: 100%;
      }
      p {
        height: 150px;
      }
    }
    .net-list {
      width: 475px;
      .nlist {
        display: flex;
        flex-wrap: wrap;
        .nlist-item {
          display: inline-block;
          width: 104px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          margin: 0 14px 10px 0;
          cursor: pointer;
          border: 2px solid #245f5c;
          color: #245f5c;
          &:hover {
            background: #ff9c00;
            color: #fff;
            border: 2px solid #ff9c00;
          }
        }
        .nlist-item-active {
          background: #ff9c00;
          color: #fff;
          border: 2px solid #ff9c00;
        }
      }
      .new-info {
        margin-top: 20px;
        .net-title {
          font-size: 20px;
          color: #333;
          margin-bottom: 5px;
          font-weight: normal;
        }
      }
    }
  }
}
</style>